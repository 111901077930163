<div class="l-wrapper">
  <app-header></app-header>
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="l-main">
    <div class="l-complate">
      <div class="l-complate__main">
        <div class="l-complate__main__icon">
          <img [src]="iconSrc" alt="vector" />
        </div>
        <div class="l-complate__main__title" *ngIf="title">
          <h2 class="c-complate-title">{{ title }}</h2>
        </div>
        <div class="l-complate__main__content" *ngIf="messeges.length">
          <p *ngFor="let messege of messeges">
            {{ messege }}
          </p>
        </div>
        <div class="l-complate__src" *ngIf="src">
          <img [src]="src" alt="" class="l-complate__src__pc" />
          <img [src]="srcSp" alt="" class="l-complate__src__sp" />
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
