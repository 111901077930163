import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.scss'],
})
export class RecruitComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'はあと訪問看護ステーションの採用ページとなっております。546-0044 大阪府大阪市東住吉区北田辺２丁目３−９で働きたい方は是非、お問い合わせページからお願い致します。',
    });
    this.title.setTitle('はあと訪問看護ステーション | 採用');
  }
}
