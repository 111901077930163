import { createClient } from 'microcms-js-sdk';

export const environment = {
  production: true,
  baseURL: 'http://localhost:3000',
  mailTo: 'imajin8280@gmail.com',
  logger: 'logger',
  biller: 'logger',
  otherCategory: 'other',
};

export const client = createClient({
  serviceDomain: 'hart-houmon',
  apiKey: 'LlG78quoBj0rouf0vv9gXaI2unXopRaoHH7g',
});
