<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-urobac">
    <div class="l-urobac__main">
      <img
        alt="外出をもっと快適に楽しみたいから|ウロバック医療用チューブカバー|6,000円(税込）"
        src="./../../../../assets/img/urobac_main.png"
      />
    </div>
    <div class="l-urobac__content1">
      <h2 class="l-urobac__content1__title">
        外出時のお悩みを解決します
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <div class="l-urobac__content1__list">
        <ul class="l-urobac__content1__list__card">
          <li class="l-urobac__content1__list__card__description">
            <p>
              外出時にウロバックを他人には見られたくない、プライバシーは守りたい！<br />
              という方のために作られた医療用カバーです。
            </p>
          </li>
          <li class="l-urobac__content1__list__card__description">
            <p>
              普通のバッグと変わらないデザインなので周囲を気にせずにお出かけできます。
            </p>
          </li>
          <li class="l-urobac__content1__list__card__description">
            <p>
              ウロバックと医療用チューブが見えないように工夫されているので、中身が透けず
              安心してお使いいただけます。
            </p>
          </li>
        </ul>
        <img alt="" src="./../../../../assets/img/uroback_content1_icon.png" />
      </div>
    </div>
    <div class="l-urobac__content2">
      <h2 class="l-urobac__content2__title">
        製品の特徴
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <div class="l-urobac__content2__list">
        <img
          class="l-urobac__content2__list-pc"
          alt=""
          src="./../../../../assets/img/urobac_content2-pc_icon.png"
        />
        <img
          class="l-urobac__content2__list-sp"
          alt=""
          src="./../../../../assets/img/urobac_content2-sp_icon.png"
        />
      </div>

      <div class="l-urobac__content2__title">
        <h2 class="l-urobac__content2__title-pc">使い方は簡単</h2>
        <span class="l-urobac__content2__sub-title-pc">2ステップ</span>
        <h2 class="l-urobac__content2__title-sp">使い方は簡単2ステップ</h2>
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </div>
      <div class="l-urobac__content2__list2">
        <div class="l-urobac__content2__list2__step">
          <img
            alt=""
            src="./../../../../assets/img/urobac_content2_list1.png"
          />
          <p>
            カバー底のファスナーを開け、一番上のボタンからウロバックを折り畳みいれて
            ください。
          </p>
        </div>
        <div class="l-urobac__content2__list2__step">
          <img
            alt=""
            src="./../../../../assets/img/urobac_content2_list2.png"
          />
          <p>
            医療用チューブの上にカバーを被せて ファスナーとボタンでとめます。
          </p>
        </div>
        <div class="l-urobac__content2__list2__sub-title">
          <p>ウロバックを入れる際はウロの尿を廃棄してください。</p>
          <p>
            一番上のボタンの開き口が小さいため、ウロバックから尿が漏れないようご注意ください。
          </p>
        </div>
      </div>
    </div>
    <app-contact-content></app-contact-content>
    <app-footer></app-footer>
  </div>
</div>
