import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopComponent } from './component/pages/top/top.component';
import { CompleteComponent } from './component/pages/complete/complete.component';
import { ContactComponent } from './component/pages/contact/contact.component';
import { AboutComponent } from './component/pages/about/about.component';
import { UrobacComponent } from './component/pages/urobac/urobac.component';
import { RecruitComponent } from './component/pages/recruit/recruit.component';
import { BlogListComponent } from './component/pages/blog-list/blog-list.component';
import { BlogContentComponent } from './component/pages/blog-content/blog-content.component';

const routes: Routes = [
  { path: '', component: TopComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'complete',
    component: CompleteComponent,
    data: { animationState: 'detailPage' },
  },
  {
    path: 'about',
    component: AboutComponent,
    data: { animationState: 'detailPage' },
  },
  {
    path: 'urobac',
    component: UrobacComponent,
    data: { animationState: 'detailPage' },
  },
  { path: 'recruit', component: RecruitComponent },
  { path: 'blog-list', component: BlogListComponent },
  { path: 'blog-content/:id', component: BlogContentComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
