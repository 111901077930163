<div class="l-warpper">
  <div class="l-contact">
    <div class="l-contact__title">
      <h2>お問い合わせ</h2>
      <img alt="" src="./../../../../assets/img/content3_line.png" />
      <span class="l-contact__title__description"
        >まずはお気軽にお問い合わせください。</span
      >
    </div>
    <div class="l-contact__link">
      <a class="c-tel" href="tel:+81-6-4305-7195">
        <img src="./../../../../assets/img/tel.png" />
        <span>06-4305-7195</span>
        <p>受付時間 平日9：00～17：00</p>
      </a>
      <div class="c-button-contact">
        <a class="c-contact" href="/contact"
          >お問い合わせフォームへ
          <img src="./../../../../assets/img/arrow.png" />
        </a>
      </div>
    </div>
  </div>
</div>
