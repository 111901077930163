import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
// import { EFormStatus } from 'src/app/enum/form-status.enum';
// import { IOption } from 'src/app/model/option.model';
// import { ValidationMessageService } from 'src/app/service/validation-message.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-radiobox',
  templateUrl: './radiobox.component.html',
  styleUrls: ['./radiobox.component.scss'],
})
export class RadioboxComponent implements ControlValueAccessor, OnInit {
  @Input() type = 'default';
  @Input() name = uuidv4();
  @Input() label = '';
  @Input() options: any = [{ label: true, value: '' }];
  @Input() layout = 'default';
  @Input() checked: any = true;
  @Input() require = false;

  @ViewChildren('radiobox') elements: ElementRef[] = [];

  onChange: (value: any) => void;
  onTouched: () => void;

  constructor(
    @Self() @Optional() public control: NgControl // public validationMessageService: ValidationMessageService
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit() {}
  // get required() {
  //   const validator = this.control?.control?.validator;
  //   return validator
  //     ? validator({} as FormControl<string | number>)?.required || false
  //     : false;
  // }

  // get isReadOnly() {
  //   return this.status === EFormStatus.READONLY;
  // }

  // get isDisabled() {
  //   return this.status === EFormStatus.DISABLED;
  // }

  // get isSideLayout() {
  //   return this.layout === 'side';
  // }

  // ControlValueAccessor
  writeValue(value: any) {
    setTimeout(() => {
      this.elements
        .map((element) => element.nativeElement)
        .forEach((element) => (element.checked = element.value === value));
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
