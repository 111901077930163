import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { client } from './../../../../environments/environment';
const moment = require('moment');

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public data = {
    endpoint: 'blogs',
    queries: {
      orders: '-publishedAt',
      limit: 12,
      offset: 0,
      filters: '',
    },
  };

  public list: {
    contents: [
      {
        id: '';
        content: 0;
        title: '';
        category: { name: '' };
        eyecatch: { url: '' };
        publishedAt: '';
      }
    ];
    limit: 0;
    offset: 0;
    totalCount: 0;
  };

  public categoryList: {
    contents: [
      {
        name: '';
        id: '';
      }
    ];
  };

  ngOnInit(): void {
    const num = this.activatedRoute.snapshot.queryParams.offset;
    this.data.queries.offset = num ? Number(num) * 12 : 0;
    this.getList();
    this.getCategoryList();
    this.title.setTitle('はあと訪問看護ステーション | ブログ一覧');
    this.meta.addTag({
      name: 'description',
      content:
        'はあと訪問看護ステーションが提供するブログとなっております。是非ご覧いただけると幸いです。',
    });
  }

  public format(date: string, formatValue: string): any {
    return moment(date).format(formatValue);
  }

  public formatDate(date: string): any {
    return this.format(date, 'YYYY年MM月DD日');
  }

  public getList() {
    const category = this.activatedRoute.snapshot.queryParams.category;
    this.data.queries.filters = category ? 'category[equals]' + category : '';

    from(client.get(this.data)).subscribe((data) => {
      console.log(data);
      this.list = data;
    });
  }

  public getCategoryList() {
    from(
      client
        .get({
          endpoint: 'categories',
          queries: { orders: '-publishedAt', limit: 100, offset: 0 },
        })
        .then((data) => {
          console.log(data);
          this.categoryList = data;
        })
    );
  }

  public getCategory() {
    return {
      filters: 'category[equals]2-wlrejtdwi',
    };
  }

  public getTime() {
    // console.log(new Date('2023, 02, 01, 00, 00').toISOString());
    // console.log(new Date('2023, 03, 01').toISOString());
    // console.log(new Date('2023, 04, 01').toISOString());

    const newDate = new Date('2023, 02, 01');
    const startAt = moment()
      .tz('Asia/Tokyo')
      .set('month', Number(newDate.getMonth()))
      .set('date', 1)
      .hour(+9)
      .minutes(0)
      .seconds(0)
      .millisecond(0);
    const endAt = moment()
      .tz('Asia/Tokyo')
      .set('month', Number(newDate.getMonth()))
      .set('date', 1)
      .hour(+9)
      .minutes(0)
      .seconds(0)
      .millisecond(0)
      .add(1, 'M');
    console.log(new Date(startAt).toISOString());
    console.log(new Date(endAt).toISOString());

    return {
      // filters:
      //   'publishedAt[less_than]' +
      //   new Date(startAt).toISOString() +
      //   '[and]publishedAt[greater_than]' +
      //   new Date(endAt).toISOString(),
      filters: 'publishedAt[less_than]' + new Date(endAt).toISOString(),
    };
  }
  //  ?"filters=category[equals]engineering[and](date[begins_with]2019-11-14[or]title[exists])" -H "X-MICROCMS-API-KEY: {api-key}"

  public pageEmit(value: any) {
    this.data.queries.offset = value ? Number(value) * 12 : 0;
    this.getList();
    this.router.navigate(['/blog-list'], {
      queryParams: {
        offset: value,
      },
    });
  }
}
