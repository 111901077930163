<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-blog" *ngIf="blog">
    <div class="l-blog__date">{{ formatDate(blog.publishedAt) }}</div>
    <div class="l-blog__title">{{ blog.title }}</div>
    <img
      class="l-blog__main__img"
      [src]="
        blog?.eyecatch?.url ? blog.eyecatch.url : '/assets/img/no_image.jpeg'
      "
      alt=""
    />
    <div [innerHTML]="blog.content"></div>
  </div>
  <app-footer></app-footer>
</div>
