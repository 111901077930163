import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../base/http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarService } from '../../atom/snackbar/snackbar.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public formGroup: FormGroup;
  constructor(
    private httpService: HttpService,
    private router: Router,
    public snackbarService: SnackbarService,
    private meta: Meta,
    private title: Title
  ) {
    this.createFormGroup();
  }

  public createFormGroup() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      kana: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      tel: new FormControl('', [Validators.required]),
      genre: new FormControl('訪問看護について', [Validators.required]),
      content: new FormControl('', [Validators.required]),
    });
  }

  public isLoading = false;
  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'はあと訪問看護ステーションのお問い合わせページとなっております。何かあればこちらから、問い合わせてもらえればと思います。',
    });
    this.title.setTitle('はあと訪問看護ステーション | お問い合わせ');
  }

  public onLoading(isLoading: any) {
    this.isLoading = isLoading;
  }

  public onAccept() {
    if (this.formGroup.valid) {
      this.httpService.post('contact', this.formGroup.value).subscribe(
        (value) => {
          if (value.stuats === 200) {
            this.router.navigate(['/complete'], {
              queryParams: {
                iconSrc: '/assets/img/vector.png',
                title: 'お問い合わせありがとうございました。',
                messege:
                  '内容を確認次第、3営業日以内に担当者よりご連絡いたします。\n\r恐れ入りますが、今しばらくお待ちください。',
              },
            });
          } else {
            // this.onError.emit(value);
          }
        },
        (error) => console.log(error),
        () => console.log('onCompleted')
      );
    } else {
      this.snackbarService.pushValidationMessage();
    }
  }
}
