<div class="c-paging" *ngIf="count()">
  <div *ngIf="offset !== count().length">
    <p
      *ngIf="0 < nowPage()"
      (click)="onPageing(nowPage() - 1)"
      class="c-paging__type"
    >
      <
    </p>
  </div>
  <ng-container>
    <div *ngFor="let item of count(); index as i">
      <ng-container *ngIf="item !== nowPage()">
        <p class="c-paging__number" (click)="onPageing(item)">
          {{ item + 1 }}
        </p>
      </ng-container>
      <p *ngIf="item === nowPage()" class="c-paging__number u-select">
        {{ item + 1 }}
      </p>
    </div>
  </ng-container>

  <div *ngIf="offset !== count().length">
    <p
      *ngIf="count().length - 1 > nowPage()"
      class="c-paging__type"
      (click)="onPageing(nowPage() + 1)"
    >
      >
    </p>
  </div>
</div>
