<div class="l-header">
  <a href="/">
    <h1 class="l-header__logo">
      <img
        alt="はあと訪問看護ステーション | 株式会社アイズ"
        src="./../../../../assets/img/logo/logo-main.png"
      />
    </h1>
  </a>
  <div class="l-header__sns">
    <a class="c-sns" href="https://www.instagram.com/osaka_hearthoumon/">
      <img
        src="./../../../../assets/img/instagram.png"
        alt="https://www.instagram.com/osaka_hearthoumon/"
      />
    </a>
  </div>
  <div class="l-header__tel">
    <a class="c-tel" href="tel:+81-6-4305-7195">
      <img src="./../../../../assets/img/tel.png" alt="06-4305-7195" />
      <span>06-4305-7195</span>
    </a>
  </div>
  <div class="l-header__contact">
    <a class="c-contact" href="/contact"
      >お問い合わせ
      <img src="./../../../../assets/img/mail.png" alt="お問い合わせ" />
    </a>
  </div>
  <ul class="l-header__list">
    <li>
      <a class="" href="/">
        <span class="c-list-title">ホーム</span
        ><span class="c-list-content">Home</span>
      </a>
    </li>
    <li>
      <a class="" href="/about">
        <span class="c-list-title">会社紹介</span
        ><span class="c-list-content">Company</span>
      </a>
    </li>
    <li>
      <a class="" href="/urobac">
        <span class="c-list-title">介護用品</span
        ><span class="c-list-content">Product</span>
      </a>
    </li>
    <li>
      <a class="" href="/recruit">
        <span class="c-list-title">採用情報</span
        ><span class="c-list-content">Recruit</span>
      </a>
    </li>
    <!-- <li>
      <a class="" href="http://en-gage.net/grouphomeemu_jobs/work_3815615">
        <span class="c-list-title">介護福祉士の募集</span
        ><span class="c-list-content">Care Recruit </span>
      </a>
    </li> -->
  </ul>
  <div class="l-header__menu" *ngIf="!isHumberger">
    <img
      src="./../../../../assets/img/menu.png"
      alt="メニュー"
      (click)="onHumberger()"
    />
  </div>
  <div class="l-header__menu" *ngIf="isHumberger">
    <img
      src="./../../../../assets/img/close.png"
      alt="メニュー"
      (click)="onHumberger()"
    />
  </div>
  <div class="l-header__panel" *ngIf="isHumberger">
    <ul>
      <li>
        <a href="/"
          >ホーム <span class="l-header__panel__sub-title">Home</span></a
        >
      </li>
      <li>
        <a href="/about"
          >会社紹介 <span class="l-header__panel__sub-title">Company</span></a
        >
      </li>
      <li>
        <a href="/urobac"
          >介護用品 <span class="l-header__panel__sub-title">Product</span></a
        >
      </li>
      <li>
        <a href="/recruit"
          >採用情報 <span class="l-header__panel__sub-title">Recruit</span></a
        >
      </li>
      <!-- <li>
        <a href="http://en-gage.net/grouphomeemu_jobs/work_3815615"
          >介護福祉士の募集
          <span class="l-header__panel__sub-title">Care Recruit</span></a
        >
      </li> -->
      <li>
        <a class="c-contact" href="/contact"
          >お問い合わせ
          <img src="./../../../../assets/img/mail.png" alt="お問い合わせ" />
        </a>
      </li>
    </ul>
  </div>
</div>
