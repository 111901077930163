<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-main">
    <div class="l-main__content1">
      <img
        alt="患者さんの事を第一に考えて行動しております。"
        src="./../../../../assets/img/main.png"
      />
    </div>
    <div class="l-main__content2">
      <img alt="" src="./../../../../assets/img/content2-left.png" />
      <div class="l-main__content2__text">
        <div>はあと訪問看護ステーションは</div>
        <div>ご自宅での療養をサポートする</div>
        <div>訪問看護サービスを行っています。</div>
        <a href="/about" class="c-button-company-pc">
          <span>会社紹介</span>
          <img alt="" src="./../../../../assets/img/arrow.png" />
        </a>
      </div>
    </div>
    <a href="/about" class="c-button-company-sp">
      <span>会社紹介</span>
      <img alt="" src="./../../../../assets/img/arrow.png" />
    </a>
    <div class="l-main__content3">
      <div class="l-main__content3__title">
        <h2>私たちのサービス</h2>
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </div>
      <ul class="l-main__content3__list">
        <li>
          <img alt="" src="./../../../../assets/img/content3_list1.png" />
          <div>
            <p class="l-main__content3__list__title">療養生活のお世話</p>
            <p class="l-main__content3__list__text">
              入浴の介助、食事や排せつの介助や指導を行います。<br />床ずれ防止の指導を行います。
            </p>
          </div>
        </li>
        <li>
          <img alt="" src="./../../../../assets/img/content3_list2.png" />
          <div>
            <p class="l-main__content3__list__title">
              病気、障害の状態観察など
            </p>
            <p class="l-main__content3__list__text">
              医師の指示で状態に合わせて点滴や適切な処置を行います。
            </p>
          </div>
        </li>
        <li>
          <img alt="" src="./../../../../assets/img/content3_list3.png" />
          <div>
            <p class="l-main__content3__list__title">ターミナルケア</p>
            <p class="l-main__content3__list__text">
              住み慣れた場所でその人らしい生活を最後まで送っていただけるよう支援します。
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="l-main__content4">
      <div class="l-main__content4__title">
        <h2>患者様のお声</h2>
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </div>
      <div class="l-main__content4__list">
        <div class="l-main__content4__list__card">
          <h3 class="l-main__content4__list__card__title">
            <img src="./../../../../assets/img/fukidasi-1.png" />ご本人より
          </h3>
          <div class="l-main__content4__list__card__description">
            <p>
              親切な対応をして頂きました。<br />来て頂いた看護師さんはどんな嫌なことも決して笑顔を絶やさずにやってくださるので本当に助かります。
            </p>
          </div>
        </div>
        <div class="l-main__content4__list__img1">
          <img alt="" src="./../../../../assets/img/content4_list1.png" />
        </div>
      </div>

      <div class="l-main__content4__list">
        <div class="l-main__content4__list__img">
          <img alt="" src="./../../../../assets/img/content4_list2.png" />
          <img alt="" src="./../../../../assets/img/content4_list3.png" />
        </div>
        <div class="l-main__content4__list__card">
          <h3 class="l-main__content4__list__card__title -greeen">
            <img src="./../../../../assets/img/fukidasi-2.png" />ご本人より
          </h3>
          <div class="l-main__content4__list__card__description">
            <p>
              急なことでもすぐに来てくれてとても安心しました。<br />
              家族の私たちにも親切にして頂き感謝しています。
              何よりも母の笑顔が最後まで見れて良かったです。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="l-main__content5">
      <div class="l-main__content5__title">
        <h2>採用について</h2>
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </div>
      <div class="l-main__content5__img">
        <img alt="" src="./../../../../assets/img/content5_background.png" />
      </div>
      <div class="l-main__content5__block">
        <div class="l-main__content5__card">
          <span class="l-main__content5__card__title"
            >私たちと一緒に働きませんか？</span
          >

          <p class="l-main__content5__card__description">
            スタッフ同士仲が良くアットホームな雰囲気です。<br />
            入社後のサポートも手厚く、安心して働ける職場です。<br />
            一緒に働いてくれる仲間を募集しています。
          </p>

          <a href="" class="c-button-company-pc">
            <span>採用情報</span>
            <img alt="" src="./../../../../assets/img/arrow.png" />
          </a>
          <a href="" class="c-button-company-sp">
            <span>採用情報</span>
            <img alt="" src="./../../../../assets/img/arrow.png" />
          </a>
        </div>
      </div>
    </div>
    <app-contact-content></app-contact-content>
    <div class="l-main__content6">
      <img
        alt="患者さんのことを第一に考えて行動しております"
        src="./../../../../assets/img/content6.png"
      />
    </div>
    <app-footer></app-footer>
  </div>
</div>
