<app-loading *ngIf="isLoading"></app-loading>
<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-contact">
    <div class="l-contact__main">
      <img
        class="c-pc"
        alt=""
        src="./../../../../assets/img/contact-pc-main.png"
      />
      <img
        class="c-sp"
        alt=""
        src="./../../../../assets/img/contact-sp-main.png"
      />
    </div>
    <div class="l-contact__content1">
      <h2 class="l-contact__content1__title">
        当社に関するご質問がありましたら下記フォームよりお問い合わせください。
      </h2>
      <h2 class="l-contact__content1__title">
        お電話でのお問い合わせは<span class="c-contact-tel">06-4305-7195</span
        >までお願いいたします。
      </h2>
    </div>
    <form [formGroup]="formGroup">
      <div class="l-contact__form">
        <app-input
          label="お名前"
          [require]="true"
          placeholder="お名前をご入力ください"
          formControlName="name"
        ></app-input>
        <app-input
          label="フリガナ"
          [require]="true"
          placeholder="フリガナをご入力ください"
          formControlName="kana"
        ></app-input>
        <app-input
          label="メールアドレス"
          type="email"
          [require]="true"
          placeholder="メールアドレスをご入力ください"
          formControlName="email"
        ></app-input>
        <app-input
          label="電話番号"
          [require]="true"
          placeholder="電話番号をご入力ください"
          formControlName="tel"
        ></app-input>
        <app-radiobox
          name="genre"
          label="お問い合わせ内容"
          [require]="true"
          [options]="[
            { value: '訪問看護について', label: '訪問看護について' },
            { value: '採用について', label: '採用について' },
            { value: 'その他', label: 'その他' }
          ]"
          formControlName="genre"
          [checked]="formGroup.value.genre"
        ></app-radiobox>
        <app-textarea
          placeholder="お問い合わせ内容をご入力ください"
          formControlName="content"
        ></app-textarea>
      </div>
      <button class="c-button-contact" (click)="onAccept()">
        確認して送信する
      </button>
    </form>
    <app-footer></app-footer>
  </div>
</div>
