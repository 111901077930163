import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../base/http.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private httpService: HttpService, private router: Router) {}
  @Input() class: string = '';

  public isHumberger = false;
  public isPanel = false;
  public account: any = { type: '' };

  ngOnInit(): void {}

  public onHumberger(): any {
    this.isHumberger = !this.isHumberger;

    if (!this.isHumberger) {
      let body: any = document.getElementsByTagName('body');
      body.item(0).removeChild(body.item(0).firstChild);
      let ele: any = body.item(0);
      ele.setAttribute('style', 'overflow: auto;');
    } else {
      const body: any = document.getElementsByTagName('body');
      const ele: any = body.item(0);
      ele.setAttribute('style', 'overflow: hidden;');

      const newEle = document.createElement('div');
      newEle.setAttribute(
        'style',
        'height: 100vh;width: 100vh;background:rgba(226, 226, 226,0.5);z-index: 2;position: absolute;transition: all 0.5s;left: 0;'
      );
      ele.prepend(newEle);
    }
  }

  public onMenuScroll(menuClass: string): any {
    this.isHumberger = !this.isHumberger;

    const body: any = document.getElementsByTagName('body');
    body.item(0).removeChild(body.item(0).firstChild);
    const ele: any = body.item(0);
    ele.setAttribute('style', 'overflow: auto;');

    const element = document.getElementById(menuClass);
    const rect = element.getBoundingClientRect();
    const elemtop = rect.top + window.pageYOffset;
    scroll(elemtop - 100, elemtop - 100);
  }

  public onLogout() {
    this.httpService.post('local/logout', {}).subscribe(
      (value) => this.router.navigate(['/'], {}),
      (error) => console.log(error),
      () => console.log('onCompleted')
    );
  }
}
