import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { from } from 'rxjs';
const moment = require('moment');
import { client } from './../../../../environments/environment';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss'],
})
export class BlogContentComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public blog = {
    publishedAt: '',
    title: '',
    content: '',
    eyecatch: { url: '' },
  };
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      from(
        client
          .get({
            endpoint: 'blogs',
            contentId: id,
          })
          .then((res) => {
            res.content = this.sanitizer.bypassSecurityTrustHtml(res.content);
            this.blog = res;
            this.title.setTitle('はあと訪問看護ステーション | ' + res.title);
            this.meta.addTag({
              name: 'description',
              content:
                res.content.changingThisBreaksApplicationSecurity.replace(
                  /<("[^"]*"|'[^']*'|[^'">])*>|\n/g,
                  ''
                ),
            });
          })
      );
    });
  }

  public format(date: string, formatValue: string): any {
    return moment(date).format(formatValue);
  }

  public formatDate(date: string): any {
    return this.format(date, 'YYYY年MM月DD日');
  }
}
