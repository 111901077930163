import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SimpleModalModule } from 'ngx-simple-modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './component/organisms/footer/footer.component';
import { HeaderComponent } from './component/organisms/header/header.component';
import { TopComponent } from './component/pages/top/top.component';
import { ContactComponent } from './component/pages/contact/contact.component';
import { ContactModule } from './component/pages/contact/contact.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgMessageComponent } from './component/molecules/ng-message/ng-message.component';
import { CompleteComponent } from './component/pages/complete/complete.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TitleComponent } from './component/atom/title/title.component';
import { ContactFormComponent } from './component/organisms/contact-form/contact-form.component';
import { ButtonComponent } from './component/atom/button/button.component';
import { IgxChipsModule, IgxIconModule } from 'igniteui-angular';
import { PrivacyPolicyComponent } from './component/pages/privacy-policy/privacy-policy.component';
import { CheckboxComponent } from './component/atom/checkbox/checkbox.component';
import { ConfrimComponent } from './component/organisms/modal/confrim/confrim.component';

import { IgxCarouselModule } from 'igniteui-angular';
import { LoadingComponent } from './component/atom/loading/loading.component';
import { PrivacyPolicyCheckComponent } from './component/organisms/modal/privacy-policy-check/privacy-policy-check.component';
import { PrivacyPolicyTemplateComponent } from './component/template/modal/privacy-policy-template/privacy-policy-template.component';
import { PagingSearchComponent } from './component/molecules/paging-search/paging-search.component';
import { InputComponent } from './component/atom/input/input.component';
import { TextareaComponent } from './component/atom/textarea/textarea.component';
import { SelectboxComponent } from './component/atom/selectbox/selectbox.component';
import { RadioboxComponent } from './component/atom/radiobox/radiobox.component';
import { AboutComponent } from './component/pages/about/about.component';
import { SnackbarComponent } from './component/atom/snackbar/snackbar.component';
import { UrobacComponent } from './component/pages/urobac/urobac.component';
import { ContactContentComponent } from './component/pages/contact-content/contact-content.component';
import { RecruitComponent } from './component/pages/recruit/recruit.component';
import { BlogListComponent } from './component/pages/blog-list/blog-list.component';
import { BlogContentComponent } from './component/pages/blog-content/blog-content.component';
import { PageingComponent } from './component/organisms/pageing/pageing.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TopComponent,
    ContactComponent,
    NgMessageComponent,
    CompleteComponent,
    TitleComponent,
    ContactFormComponent,
    ButtonComponent,
    PrivacyPolicyComponent,
    CheckboxComponent,
    ConfrimComponent,
    LoadingComponent,
    PrivacyPolicyCheckComponent,
    PrivacyPolicyTemplateComponent,
    PagingSearchComponent,
    InputComponent,
    TextareaComponent,
    SelectboxComponent,
    RadioboxComponent,
    AboutComponent,
    SnackbarComponent,
    UrobacComponent,
    ContactContentComponent,
    RecruitComponent,
    BlogListComponent,
    BlogContentComponent,
    PageingComponent,
  ],
  imports: [
    SimpleModalModule,
    BrowserModule,
    AppRoutingModule,
    ContactModule,
    EditorModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IgxChipsModule,
    IgxIconModule,
    IgxCarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
