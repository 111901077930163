<div class="l-form">
  <label>
    <ng-container *ngIf="label">{{ label }}</ng-container>
  </label>
  <div>
    <ng-container *ngIf="require">
      <span class="c-require"> 必須 </span>
    </ng-container>
  </div>
  <div class="l-form__content">
    <ul class="l-radiobox">
      <li *ngFor="let option of options">
        <input
          type="radio"
          [name]="name"
          [value]="option.value"
          [id]="name + '-' + option.label"
          (input)="onChange(option.value)"
          [checked]="option.value === checked"
        /><label [for]="name + '-' + option.label">
          <span>{{ option.label }}</span>
        </label>
      </li>
    </ul>
  </div>
  <div class="l-error">
    <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
      <div class="error-messege" *ngIf="control?.errors?.required">
        {{ label }}を入力してください。
      </div>
    </div>
  </div>
</div>
