import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content: 'はあと訪問看護ステーションの会社概要ページとなっております。',
    });
    this.title.setTitle('はあと訪問看護ステーション | 会社概要');
  }
}
