<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-recruit">
    <div class="l-recruit__main">
      <img
        class="c-pc"
        alt=""
        src="./../../../../assets/img/recruit-pc-main.png"
      />
      <img
        class="c-sp"
        alt=""
        src="./../../../../assets/img/recruit-sp_main.png"
      />
    </div>
    <div class="l-recruit__content1">
      <h2 class="l-recruit__content1__title">
        私たちと一緒に働きませんか？
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <div class="l-recruit__content1__list">
        <ul class="l-recruit__content1__list__card">
          <li class="l-recruit__content1__list__description">
            <p>
              大阪府の天王寺駅から一駅のところにある訪問看護ステーションです。
            </p>
          </li>

          <li class="l-recruit__content1__list__description">
            <p>
              スタッフ同士仲が良くアットホームな雰囲気で、仲間をすぐに迎え入れる準備はできています！
            </p>
          </li>
          <li class="l-recruit__content1__list__description">
            <p>
              一緒に働いてくれる仲間を募集中なので、気になった方はぜひお問い合わせをお願いいたします。<br />病院ではできなかったこと、今まで出来なかったことを少しでも叶え、利用者様が自宅で生活を満喫できる、そんなケアを提供しています。
            </p>
          </li>
        </ul>
        <!-- <img alt="" src="./../../../../assets/img/recruit.png" /> -->
        <img class="c-img" alt="" src="./../../../../assets/img/recruit2.jpg" />
      </div>
    </div>
    <div class="l-recruit__content2">
      <h2 class="l-recruit__content2__title">
        会社概要 <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <ul class="l-recruit__content2__list">
        <li class="l-recruit__content2__list__title">事業内容</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>訪問看護</p>
            <p>居宅で療養している患者への看護業務</p>
            <p>・バイタルサインのチェック</p>
            <p>・褥瘡処置、点滴処置、服薬管理、医療相談 など</p>
          </div>
        </li>
        <li class="l-recruit__content2__list__title">給与</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>【月収】260,000円～320,000円</p>
            <p>【昇給】年1回</p>
            <p>【賞与】年回4.5か月／過去実績</p>
            <p>【基本給】230,000円～</p>
          </div>
        </li>
        <li class="l-recruit__content2__list__title">勤務地</li>
        <li class="l-recruit__content2__list__description">
          〒546-0044 大阪府大阪市東住吉区北田辺2-3-9 1 F
        </li>
        <li class="l-recruit__content2__list__title">アクセス</li>
        <li class="l-recruit__content2__list__description">
          JR阪和線（天王寺－和歌山）「美章園」駅 徒歩4分
        </li>
        <li class="l-recruit__content2__list__title">雇用形態</li>
        <li class="l-recruit__content2__list__description">正社員</li>
        <li class="l-recruit__content2__list__title">勤務時間</li>
        <li class="l-recruit__content2__list__description">
          09：00～18：00（休憩60分）
        </li>
        <li class="l-recruit__content2__list__title">休暇</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>有休休暇・その他休暇 シフト制（変形4週間）</p>
            <p>年間休日105日</p>
          </div>
        </li>
        <li class="l-recruit__content2__list__title">必要な資格</li>
        <li class="l-recruit__content2__list__description">看護師</li>
        <li class="l-recruit__content2__list__title">応募条件</li>
        <li class="l-recruit__content2__list__description">
          正看護師免許をお持ちの方
        </li>
        <li class="l-recruit__content2__list__title">福利厚生</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>雇用保険、労災保険、健康保険、厚生年金保険、退職金</p>
            <p>■補足 定年制度あり(一律65歳) /継続雇用制度あり</p>
          </div>
        </li>
        <li class="l-recruit__content2__list__title">各種手当</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>通勤手当</p>
            <p>資格手当（30,000円）</p>
            <p>その他手当（訪問手当30,000円～50,000円）</p>
          </div>
        </li>

        <li class="l-recruit__content2__list__title">共有スペース</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>ウォーターサーバ利用可能</p>
            <p>休憩時間にはちょっとしたお菓子を楽しめる</p>
            <p>訪問先に持ち運べるお茶を常備</p>
          </div>
        </li>

        <li class="l-recruit__content2__list__title">入職祝い金制度</li>
        <li class="l-recruit__content2__list__description">
          <div>
            <p>ダイレクト応募</p>
            <p>ハローワークから応募</p>
            <p>のみ</p>
          </div>
        </li>
      </ul>
    </div>
    <app-contact-content></app-contact-content>
    <app-footer></app-footer>
  </div>
</div>
