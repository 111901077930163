<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-about">
    <div class="l-about__main">
      <img
        class="c-pc"
        alt=""
        src="./../../../../assets/img/about-pc-main.png"
      />
      <img
        class="c-sp"
        alt=""
        src="./../../../../assets/img/about-sp-main.png"
      />
    </div>
    <div class="l-about__content1">
      <h2 class="l-about__content1__title">
        患者様への思い
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <div class="l-about__content1__list">
        <ul class="l-about__content1__list__card">
          <li class="l-about__content1__list__card__title">
            <p>患者様が自宅で自分らしく過ごすサポートを</p>
          </li>
          <li class="l-about__content1__list__card__description c-pc">
            <p>
              私たちは皆様の小さなサインも見逃さないように、しっかりとした目で観察を行っています。<br />病院ではできなかったこと、今まで出来なかったことを少しでも叶え、利用者様が自宅で生活を満喫できる、そんなケアを提供しています。
            </p>
          </li>
          <li class="l-about__content1__list__card__description c-pc">
            <p>
              ご家族が感じる介護における不安、不満などをしっかり受け止め、皆様<br />が毎日を楽しんで生活できるよう支援を行っております。
            </p>
          </li>
          <li class="l-about__content1__list__card__description c-pc">
            <p>代表 松本由美</p>
          </li>
        </ul>
        <div>
          <img
            class="l-about__content1__list__img"
            alt=""
            src="./../../../../assets/img/about.png"
          />
        </div>
        <ul class="l-about__content1__list__card2 c-sp">
          <li class="l-about__content1__list__card__description">
            <p>
              私たちは皆様の小さなサインも見逃さないように、しっかりとした目で観察を行っています。<br />病院ではできなかったこと、今まで出来なかったことを少しでも叶え、利用者様が自宅で生活を満喫できる、そんなケアを提供しています。
            </p>
          </li>
          <li class="l-about__content1__list__card__description">
            <p>
              ご家族が感じる介護における不安、不満などをしっかり受け止め、皆様<br />が毎日を楽しんで生活できるよう支援を行っております。
            </p>
          </li>
          <li class="l-about__content1__list__card__description">
            <p>代表 松本由美</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="l-about__content1">
      <h2 class="l-about__content1__title">
        覚悟の瞬間(とき)
        <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <div class="l-about__content1__list">
        <div>
          <img
            class="l-about__content1__list__img2"
            alt=""
            src="./../../../../assets/img/kakugonotoki.png"
          />
        </div>

        <ul class="l-about__content1__list__card">
          <li class="l-about__content1__list__card__title">
            <p>代表の松本由美が取材を受けました</p>
          </li>
          <li class="l-about__content1__list__card__description c-pc">
            <p>
              覚悟の瞬間とは様々な業界の方々から取材したWebメディアのサービスです。<br />看護師になった理由から若者へのメッセージなど幅広くお話をしております。<br />約7分ほどお話をしているので、ぜひ御覧ください。
              <br />
              URL:<a
                href="https://www.kakugo.tv/person/detkpv5zb.html"
                target="_blank"
                style="word-wrap: break-word"
                >https://www.kakugo.tv/person/detkpv5zb.html</a
              >
            </p>
          </li>
          <li class="l-about__content1__list__card__description"></li>
        </ul>
        <ul class="l-about__content1__list__card2 c-sp">
          <li class="l-about__content1__list__card__description">
            <p>
              覚悟の瞬間とは様々な業界の方々から取材したWebメディアのサービスです。
            </p>
          </li>
          <li class="l-about__content1__list__card__description">
            覚悟の瞬間とは様々な業界の方々から取材したWebメディアのサービスです。<br />看護師になった理由から若者へのメッセージなど幅広くお話をしております。<br />約7分ほどお話をしているので、ぜひ御覧ください。
            <br />
            URL:<a
              href="https://www.kakugo.tv/person/detkpv5zb.html"
              target="_blank"
              style="word-wrap: break-word"
              >https://www.kakugo.tv/person/detkpv5zb.html</a
            >
          </li>
          <li class="l-about__content1__list__card__description"></li>
        </ul>
      </div>
    </div>
    <div class="l-about__content2">
      <h2 class="l-about__content2__title">
        会社概要 <img alt="" src="./../../../../assets/img/content3_line.png" />
      </h2>
      <ul class="l-about__content2__list">
        <li>名称</li>
        <li>株式会社アイズ</li>
        <li>設立</li>
        <li>2016年10月</li>
        <li>資本金</li>
        <li>1,000,000円</li>
        <li>代表者</li>
        <li>松本 由美</li>
        <li>本社所在地</li>
        <li>〒546-0044 大阪府大阪市東住吉区北田辺2-3-9 1 F</li>
        <li>電話番号</li>
        <li>06-4305-7195</li>
        <li>事業内容</li>
        <li>訪問看護事業</li>
      </ul>
    </div>
    <div class="l-about__content3">
      <div class="l-about__content3__title">
        <h2>アクセス</h2>
        <img alt="" src="./../../../../assets/img/content3_line.png" />
        <p class="l-about__content3__title__sub">
          <img src="./../../../../assets/img/train.png" />最寄り駅JR阪和線
          美章園駅 駅から徒歩4分
        </p>
      </div>
      <div class="l-about__content3__map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13130.750284572367!2d135.5266591!3d34.6373348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6277d23d418fbdbd!2z44Gv44GB44Go6Kiq5ZWP55yL6K2344K544OG44O844K344On44Oz!5e0!3m2!1sja!2sjp!4v1672323909258!5m2!1sja!2sjp"
          width="100%"
          height="322px"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <app-contact-content></app-contact-content>
    <app-footer></app-footer>
  </div>
</div>
