import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-urobac',
  templateUrl: './urobac.component.html',
  styleUrls: ['./urobac.component.scss'],
})
export class UrobacComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'はあと訪問看護ステーションの介護用品ページとなっております。何かあればこちらから、問い合わせてもらえればと思います。',
    });
    this.title.setTitle('はあと訪問看護ステーション | 介護用品');
  }
}
