import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'はあと訪問看護ステーションのトップページとなっております。はあと訪問看護ステーションはご自宅での療養をサポートする訪問看護サービスを行っています。',
    });
    this.title.setTitle('はあと訪問看護ステーション');
  }

  public headerClass = 'c-header-transparent';
  public scllor = '';
  @HostListener('window:scroll', []) onWindowScroll() {
    if (window.pageYOffset) {
      this.headerClass = '';
      this.scllor = '';
    } else {
      this.headerClass = 'c-header-transparent';
      this.scllor = 'scllor';
    }
  }
}
